import * as React from 'react'

import {Button, Card, Typography} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import CardContent from "@material-ui/core/CardContent";
import {Store} from "../../store";
import {setEditOrderPlacementFlowDrawer} from "./actions";

const useStyles = makeStyles({
    card: {
        display: 'flex',
        width: '180px',
        height: '100px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        '&:hover': {
            background: 'rgb(240, 240, 240)',
            cursor: 'pointer'
        }
    },
    addButton: {
        opacity: 0,
        marginTop: '0.2em',
        display: 'flex',
        alignSelf: 'center',
        width: 'fit-content',
        background: 'white',
        '&:hover': {
            background: 'rgb(240, 240, 240)',
        },
    },
    wrapper: {
        justifyContent: 'center',
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        '&:hover button': {
            transition: 'opacity 100ms ease-in',
            opacity: '1',
        },
        animationName: 'fadeIn',
        opacity: 1,
        "-webkit-animation-name": 'fadeIn',
        animationDuration: '0.5s',
        '-webkit-animation-duration': '0.5',
        animationTimingFunction: 'ease-in-out',
        '-webkit-animation-timing-function': 'ease-in-out',
        visibility: 'visible !important'
    }
});

const WebshopCard = ({orderPlacementFlow, onClickAdd}) => {
    const classes = useStyles()
    console.log(Store.getState());
    return (
        <div className={classes.wrapper}>
            <Card className={classes.card} onClick={() => {
                Store.dispatch(setEditOrderPlacementFlowDrawer(true, orderPlacementFlow));
            }}>
                <CardContent>
                    <Typography variant="h6" component="h2">{orderPlacementFlow?.webshop?.title}</Typography>
                    <Typography variant="paragraph">{orderPlacementFlow?.webshop?.name}</Typography>
                </CardContent>
            </Card>
            <Button className={classes.addButton} onClick={onClickAdd} variant={"outlined"}>+</Button>
        </div>
    )
}

export default WebshopCard;