import * as React from 'react';

const Logo = (props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 81 81" version="1.1">
            <title>Group</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="DropWonder#open-(1)" transform="translate(-545.000000, -35.000000)" fill="#71939E">
                    <g id="Group" transform="translate(545.686000, 35.708000)">
                        <path
                            d="M73.509,11.119 L73.509,50.586 C73.509,63.226 63.226,73.51 50.587,73.51 L29.241,73.51 C16.601,73.51 6.318,63.226 6.318,50.586 L6.318,29.241 C6.318,16.601 16.601,6.319 29.241,6.319 L50.587,6.319 C53.459,6.319 56.303,6.863 58.962,7.91 L58.962,1.228 C56.247,0.413 53.435,0 50.587,0 L29.241,0 C13.117,0 0,13.117 0,29.241 L0,50.586 C0,66.71 13.117,79.827 29.241,79.827 L50.587,79.827 C66.71,79.827 79.827,66.71 79.827,50.586 L79.827,29.241 C79.827,22.635 77.567,16.251 73.509,11.119"
                            id="Fill-26"/>
                        <path
                            d="M57.282,27.251 L51.607,46.231 L44.217,26.548 C43.954,25.845 43.426,25.564 42.766,25.564 L38.939,25.564 C38.28,25.564 37.752,25.845 37.554,26.548 L30.494,45.739 L24.291,27.251 C24.028,26.478 23.566,26.196 22.907,26.196 L15.979,26.196 C15.055,26.196 14.725,26.759 15.055,27.673 L26.074,57.479 C26.271,58.183 26.799,58.534 27.458,58.534 L31.549,58.534 C32.21,58.534 32.737,58.253 33.001,57.55 L40.72,37.444 L48.506,57.55 C48.705,58.253 49.232,58.534 49.892,58.534 L53.785,58.534 C54.445,58.534 54.972,58.183 55.171,57.479 L66.189,27.673 C66.518,26.759 66.189,26.196 65.265,26.196 L58.601,26.196 C57.941,26.196 57.479,26.548 57.282,27.251"
                            id="Fill-27"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Logo;
