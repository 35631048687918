import {Box, Button, Drawer, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {useDataProvider, useNotify} from "react-admin";
import {Store} from "../../store";
import {deleteOrderPlacementFLow, editOrderPlacementFlow, setEditOrderPlacementFlowDrawer} from "./actions";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    container: {
        padding: '1rem 2rem',
        width: '40rem'
    },
    button: {
        marginTop: '2em'
    },
    errorButton: {
        marginTop: '2em',
        border: '1px solid rgba(244, 67, 54, 0.5)',
        color: 'rgb(244, 67, 54)',
        '&hover': {
            border: '1px solid rgb(244, 67, 54)'
        },
    },
    title: {
        marginTop: '1em',
        marginBotton: '1em'
    },
    spacerLine: {
        paddingTop: '2em',
        borderBottom: '1px solid'
    }
});


const EditOrderPlacementFlowDrawer = (props) => {
    const classes = useStyles()
    const client = useSelector(state => state.user.client);
    const notify = useNotify();
    const flows = useSelector(state => state.orderPlacement);
    const [saveLoading, setSaveLoading] = useState(false);
    const [webshops, setWebShops] = useState([]);
    const dataProvider = useDataProvider();
    const orderPlacementFlow = useSelector(state => state.orderPlacement.editFlowDrawerOpen.orderPlacementFlow);
    const [postData, setPostData] = useState({webshopId: orderPlacementFlow?.webshop?.id})

    const fetchWebshops = useCallback(async () => {
        const {data: webshops} = await dataProvider.getList(
            'webshop',
            {
                pagination: {page: 1, perPage: 300},
                sort: {field: 'Name', order: 'DESC'},
            }
        );
        setWebShops(webshops)
    }, [dataProvider]);

    useEffect(() => {
        fetchWebshops();
    }, [fetchWebshops]); // es

    useEffect(() => {
        setPostData({webshopId: orderPlacementFlow?.webshop?.id});
    }, [orderPlacementFlow]); // es

    const editWebshop = async () => {
        const {data} = await (() => {
            try {
                return client.put(`/orderPlacementFlow/${orderPlacementFlow.id}`, postData)
            } catch (e) {
                notify("Something went wrong.", "error")
            }
        })()
        return data;
    }

    const deleteFlow = async () => {
        const {data} = await (() => {
            try {
                return client.delete(`/orderPlacementFlow/${orderPlacementFlow.id}`)
            } catch (e) {
                notify("Something went wrong.", "error")
            }
        })()
        return data;
    }
    return (
        <Drawer anchor={'right'} open={flows.editFlowDrawerOpen.drawerOpen} onClose={() => {
            Store.dispatch(setEditOrderPlacementFlowDrawer(false))
        }}>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12} md={12}>
                    <Typography className={classes.title} variant="h6" component="h2">Edit order placement
                        source</Typography>

                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        setSaveLoading(true);
                        let data = await editWebshop();
                        Store.dispatch(editOrderPlacementFlow(data.id, data))
                        Store.dispatch(setEditOrderPlacementFlowDrawer(false, null))
                        setSaveLoading(false);
                    }}>
                        <FormControl fullWidth>
                            <Box class="supplier-select">
                                <InputLabel>Product source provider</InputLabel>
                                <Select fullWidth value={postData?.webshopId ?? ""}
                                        onChange={({target}) => {
                                            setPostData({
                                                ...postData,
                                                webshopId: target.value
                                            })
                                        }}>
                                    {
                                        webshops.map(i => <MenuItem value={i.id}>{i.name}</MenuItem>)
                                    }
                                </Select>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlinedPrimary"
                                    margin={0}
                                    className={classes.button}
                                >
                                    {saveLoading ?
                                        <CircularProgress size={"1.4em"}/> : 'save'}
                                </Button>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="error"
                                    margin={0}
                                    onClick={async e => {
                                        e.preventDefault();
                                        Store.dispatch(deleteOrderPlacementFLow(orderPlacementFlow.id))
                                        Store.dispatch(setEditOrderPlacementFlowDrawer(false))
                                        await deleteFlow();
                                    }}
                                    className={classes.errorButton}
                                    startIcon={<DeleteIcon/>}
                                    disabled={saveLoading}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </FormControl>
                    </form>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default EditOrderPlacementFlowDrawer;