export const ADD_PRODUCT_IMPORT_FLOW = 'ADD_PRODUCT_IMPORT_FLOW';
export const ADD_WEBSHOP = 'ADD_WEBSHOP';
export const EDIT_WEBSHOP = 'EDIT_WEBSHOP';
export const SET_ADD_FLOW_DRAWER = 'SET_ADD_FLOW_DRAWER';
export const SET_ADD_WEBSHOP_DRAWER = 'SET_ADD_WEBSHOP_DRAWER';
export const SET_EDIT_FLOW_DRAWER = 'SET_EDIT_FLOW_DRAWER';
export const DELETE_PRODUCT_IMPORT_FLOW = 'DELETE_PRODUCT_IMPORT_FLOW';
export const DELETE_WEBSHOP = 'DELETE_WEBSHOP';
export const EDIT_PRODUCT_IMPORT_FLOW = 'EDIT_PRODUCT_IMPORT_FLOW';
export const SET_PRODUCT_IMPORT_FLOWS = 'SET_PRODUCT_IMPORT_FLOWS';

export const addProductImportFlow = (productImportFlow) => ({
    type: ADD_PRODUCT_IMPORT_FLOW,
    payload: productImportFlow,
});

export const addWebshop = (productImportFlowId, webshop) => ({
    type: ADD_WEBSHOP,
    payload: {productImportFlowId, webshop},
});

export const deleteWebshop = (productImportFlowId, webshopId) => ({
    type: DELETE_WEBSHOP,
    payload: {productImportFlowId, webshopId},
});

export const editWebshop = (productImportFlowId, webshopId, webshop) => ({
    type: EDIT_WEBSHOP,
    payload: {productImportFlowId, webshopId, webshop},
});


export const setProductImportFlows = (productImportFlows) => ({
    type: SET_PRODUCT_IMPORT_FLOWS,
    payload: productImportFlows,
});


export const setAddProductImportFlowDrawer = (drawerOpen, id) => ({
    type: SET_ADD_FLOW_DRAWER,
    payload: {drawerOpen, id},
});

export const setAddWebshopDrawer = (drawerOpen, productImportFlowId, webshopId) => ({
    type: SET_ADD_WEBSHOP_DRAWER,
    payload: {drawerOpen, productImportFlowId, webshopId},
});

export const setEditFlowDrawer = (drawerOpen, productSource) => ({
    type: SET_EDIT_FLOW_DRAWER,
    payload: {drawerOpen, productSource},
});

export const deleteProductImportFlow = (id) => ({
    type: DELETE_PRODUCT_IMPORT_FLOW,
    payload: id,
});

export const editProductImportFlow = (id, flow) => ({
    type: EDIT_PRODUCT_IMPORT_FLOW,
    payload: {id, flow},
});