import {useNotify} from "react-admin";
import {Button, Card, CardHeader, Typography} from '@material-ui/core';
import * as React from "react";
import {useEffect} from "react";
import CardContent from "@material-ui/core/CardContent";
import ProductImportFlow from "./ProductImport/ProductImportFlow";
import {makeStyles} from "@material-ui/core/styles";
import AddProductSourceDrawer from "./ProductImport/AddProductSourceDrawer";
import {useSelector} from "react-redux";
import {Store} from "../store";
import {addProductImportFlow, setAddProductImportFlowDrawer, setProductImportFlows} from "./ProductImport/actions";
import {addOrderPlacementFlow, setAddOrderPlacementFlowDrawer, setOrderPlacementFlows} from "./OrderPlacement/actions";
import EditProductSourceDrawer from "./ProductImport/EditProductSourceDrawer";
import AddWebshopDrawer from "./ProductImport/AddWebshopDrawer";
import OrderPlacementFlow from "./OrderPlacement/OrderPlacementFlow";
import AddOrderDestinationDrawer from "./OrderPlacement/AddOrderDestinationDrawer";
import AddOrderPlacementFlowDrawer from "./OrderPlacement/AddOrderPlacementFlowDrawer";
import EditOrderPlacementFlowDrawer from "./OrderPlacement/EditOrderPlacementFlowDrawer";

const useStyles = makeStyles({
    card: {
        display: 'flex', position: 'relative', height: '100%', flexDirection: 'column'
    }, cardHeader: {
        display: 'flex', paddingLeft: '1.2em', paddingTop: '1.2em'
    }, header: {
        paddingRight: '0.5em', marginRight: '0.5em', borderRight: '1px solid', alignSelf: 'center'
    },
    innerCard:{
        margin: '1.5em 0'
    },
    title:{
        padding: '1.5em',
        paddingBottom: '0',
    }
});
const Flows = (props) => {
    const classes = useStyles();
    const productImportFlows = useSelector(state => state.productImport.flows);
    const orderPlacementFlows = useSelector(state => state.orderPlacement.flows);

    const client = useSelector(state => state.user.client);
    const notify = useNotify();

    useEffect(() => {
        const func = async () => {
            const {data} = await (() => {
                try {
                    return client.get("/ProductImportFlow?include=ProductSource,Webshops")
                } catch (e) {
                    notify("Something went wrong.", "error")
                }
            })()
            Store.dispatch(setProductImportFlows(data.data));
        }
        func()
    }, [client, notify])

    useEffect(() => {
        const func = async () => {
            const {data} = await (() => {
                try {
                    return client.get("/OrderPlacementFlow?include=Webshop,OrderDestinations")
                } catch (e) {
                    notify("Something went wrong.", "error")
                }
            })()
            Store.dispatch(setOrderPlacementFlows(data.data));
        }
        func()
    }, [client, notify])
    return (
        <div>
            <Card>
                <CardHeader className={classes.title} title={'Flows'}/>
                <CardContent>
                    <Card className={classes.innerCard}>
                        <div className={classes.cardHeader}>
                            <Typography className={classes.header} variant="h6" component="h2">Product
                                Import</Typography>
                            <Button variant={"outlined"} onClick={() => {
                                Store.dispatch(setAddProductImportFlowDrawer(true, 1));
                                Store.dispatch(addProductImportFlow({id: 1}));
                            }}>+</Button>
                        </div>
                        <CardContent className={classes.card}>
                            {productImportFlows?.map((flow) => <ProductImportFlow flow={flow} />)}
                        </CardContent>
                    </Card>
                    <Card className={classes.innerCard}>
                        <div className={classes.cardHeader}>
                            <Typography className={classes.header} variant="h6" component="h2">Order Placement
                            </Typography>
                            <Button variant={"outlined"} onClick={() => {
                                Store.dispatch(setAddOrderPlacementFlowDrawer(true, 1));
                                Store.dispatch(addOrderPlacementFlow({id: 1}));
                            }}>+</Button>
                        </div>
                        <CardContent className={classes.card}>
                            {orderPlacementFlows?.map((flow) => <OrderPlacementFlow flow={flow} />)}
                        </CardContent>
                    </Card>
                </CardContent>
            </Card>
            <AddProductSourceDrawer/>
            <EditProductSourceDrawer/>
            <AddWebshopDrawer/>
            <AddOrderDestinationDrawer/>
            <AddOrderPlacementFlowDrawer/>
            <EditOrderPlacementFlowDrawer/>
        </div>
    )
}

export default Flows;
