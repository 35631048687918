import {makeStyles} from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import {Typography} from "@material-ui/core";
import SubscriptionButton from "./SubscriptionButton";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useNotify} from "react-admin";

const useStyles = makeStyles({
    cardContainer: {
        minWidth: '23rem',
        maxWidth: '23rem',
        marginRight: '2em',
        marginLeft: '2em',
        borderRadius: '2rem',
        boxShadow: '0 5px 12px 2px rgba(0,0,0,.15)'
    },
    cardBackground: {
        height: '12em',
        background: '#f12711; background: -webkit-linear-gradient(to top right, #f12711, #f5af19); background: linear-gradient(to top right, #f12711, #f5af19);',  /* fallback for old browsers */
        borderRadius: '2rem',
        padding: '2rem 2rem 0 2rem',
    },
    cardBackground__starter: {
        background: '#c2e59c; background: -webkit-linear-gradient(to bottom right, #c2e59c, #64b3f4); background: linear-gradient(to bottom right, #c2e59c, #64b3f4);',  /* fallback for old browsers */
        color: '#334655'
    },
    cardBackground__basic: {
        background: '#f12711; background: -webkit-linear-gradient(to top right, #f12711, #f5af19); background: linear-gradient(to top right, #f12711, #f5af19);',  /* fallback for old browsers */
        color: '#4f1e19'

    },
    cardBackground__pro: {
        background: '#ad5389; background: -webkit-linear-gradient(to bottom right, #ad5389, #3c1053); background: linear-gradient(to bottom right, #ad5389, #3c1053);',  /* fallback for old browsers */
        color: "#f6ecfb"
    },
    cardContent: {
        borderRadius: '2rem',
        marginTop: '-3em',
        zIndex: 2,
        background: 'white',
        padding: '2rem 2rem 2rem 2rem',
    },
    formControl: {
        width: "100%",
    },
    subscriptionName: {
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: '1rem'
    },
    subscriptionPrice: {
        marginTop: '1.2rem',
    },
    subscriptionPrice__currency: {
        position: 'relative',
        top: '-1em',
        left: 0,
        fontWeight: 600
    },
    subscriptionPrice__price: {
        fontSize: '2.5em',
        padding: '0 0.2em',
        fontWeight: 750
    },
    subscriptionPrice__interval: {
        fontWeight: 600
    },
    subscriptionSubTitle: {
        fontSize: '1.0em',
        marginTop: '0.4em'
    },
    featureList: {
        margin: 0,
        padding: 0,
        fontSize: '1.1em',
        color: '#515151',
        '& li': {
            listStyle: 'none',
            padding: '0.5em 0'
        }
    },
    buyButton: {
        marginTop: '1em',
    }
});

const SubscriptionCard = props => {
    const [loading, setLoading] = useState(false);
    const client = useSelector(state => state.user.client);
    const notify = useNotify();
    const classes = useStyles();
    let buttonEnabled = true;
    let backgroundColorClass = null;
    let percentageFee = 0;
    let monthlyFee = '0.0';
    let buttonText = 'Buy Now';
    switch (props.subscriptionType) {
        case "Starter":
            backgroundColorClass = classes.cardBackground__starter;
            percentageFee = 8;
            if (!props.hasValidSubscription || !props.active) {
                break;
            }
            if (props.usesTrial) {
                buttonText = 'Approve Charge';
                break;
            }
            buttonText = 'Your Plan';
            buttonEnabled = false;
            break;
        case "Basic":
            backgroundColorClass = classes.cardBackground__basic;
            percentageFee = 4;
            monthlyFee = '49.0';
            if (!props.hasValidSubscription || !props.active) {
                break;
            }
            buttonText = 'Your Plan';
            buttonEnabled = false;
            break;
        case "Pro":
            backgroundColorClass = classes.cardBackground__pro;
            monthlyFee = '99.0';
            percentageFee = 1;
            if (!props.hasValidSubscription || !props.active) {
                break;
            }
            buttonText = 'Your Plan';
            buttonEnabled = false;
    }
    const [stateButtonEnabled, setButtonEnabled] = useState(buttonEnabled);
    return (
        <div className={classes.cardContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
                <div className={`${classes.cardBackground} ${backgroundColorClass}`}>
                    <Typography variant="h5" className={classes.subscriptionName}>
                        {props.subscriptionType}
                    </Typography>
                    <div className={classes.subscriptionPrice}>
                        <Typography variant="span" className={classes.subscriptionPrice__currency}>
                            $
                        </Typography>
                        <Typography variant="span" className={classes.subscriptionPrice__price}>
                            {monthlyFee}
                        </Typography>
                        <Typography variant="span" className={classes.subscriptionPrice__interval}>
                            monthly
                        </Typography>
                    </div>
                    <Typography className={classes.subscriptionSubTitle}>
                        + {percentageFee}% of order value processed by Dropwonder
                    </Typography>
                </div>
                <div className={classes.cardContent}>
                    <Typography>

                    </Typography>
                    <ul className={classes.featureList}>
                        <li>
                            Import supplier data
                        </li>
                        <li>
                            Edit productdata in bulk
                        </li>
                        <li>
                            Set your own margins
                        </li>
                        <li>
                            Place orders at your supplier
                        </li>
                        <li>
                            Keep your shipping data in sync
                        </li>
                    </ul>
                    <SubscriptionButton onClick={() => {
                        setLoading(true);
                        setButtonEnabled(false);
                        buySubscription(client, props.subscriptionType).catch((e) => {
                            setButtonEnabled(true);
                            setLoading(false);
                            if (e.status === 400){
                                notify("Please connect your shopify store", "error");
                                return;
                            }
                            notify(e.message, "error");
                        });
                    }} loading={loading} disabled={!stateButtonEnabled}
                                        className={classes.buyButton} backgroundColor='#fdab7d' variant="contained"
                                        color={"secondary"} disableElevation>
                        {buttonText}
                    </SubscriptionButton>
                </div>
            </FormControl>
        </div>);
};

const buySubscription = (client, subscription) => {
    return client.post("/actions/shopify/ApplicationCharge", {subscriptionType: subscription}).then(({data}) => {
        window.location.assign(data.url);
    });
}

export default SubscriptionCard;