import {
    Box,
    Button,
    Drawer,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from "react";
import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {useNotify} from "react-admin";
import {Store} from "../../store";
import {deleteProductImportFlow, editProductImportFlow, setEditFlowDrawer} from "./actions";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    container: {
        padding: '1rem 2rem',
        width: '40rem'
    },
    button: {
        marginTop: '2em'
    },
    errorButton: {
        marginTop: '2em',
        border: '1px solid rgba(244, 67, 54, 0.5)',
        color: 'rgb(244, 67, 54)',
        '&hover': {
            border: '1px solid rgb(244, 67, 54)'
        },
    },
    title: {
        marginTop: '1em',
        marginBotton: '1em'
    },
    spacerLine: {
        paddingTop: '2em',
        borderBottom: '1px solid'
    }
});


const EditProductSourceDrawer = (props) => {
    const classes = useStyles()
    const client = useSelector(state => state.user.client);
    const notify = useNotify();
    const flows = useSelector(state => state.productImport);
    const [saveLoading, setSaveLoading] = useState(false);
    const [productSource, setProductSource] = useState(flows.editFlowDrawerOpen.productSource?.classTitle);

    const [sourceData, setSourceData,] = useState(flows.editFlowDrawerOpen.productSource);

    useEffect(() => {
        setSourceData(flows.editFlowDrawerOpen.productSource);
        setProductSource(flows.editFlowDrawerOpen.productSource?.classTitle);
    }, [flows.editFlowDrawerOpen.productSource])

    const editProductSource = async () => {
        const {data} = await (() => {
            try {
                return client.put(`/ProductImportFlow/${productSource}/${sourceData.productImportFlow.id}`, sourceData)
            } catch (e) {
                notify("Something went wrong.", "error")
            }
        })()
        return data;
    }

    const deleteFlow = async () => {
        const {data} = await (() => {
            try {
                return client.delete(`/ProductImportFlow/${sourceData.productImportFlow.id}`)
            } catch (e) {
                notify("Something went wrong.", "error")
            }
        })()
        return data;
    }
    return (
        <Drawer anchor={'right'} open={flows.editFlowDrawerOpen.drawerOpen} onClose={() => {
            Store.dispatch(setEditFlowDrawer(false))
        }}>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12} md={12}>
                    <Typography className={classes.title} variant="h6" component="h2">Edit product source</Typography>

                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        setSaveLoading(true);
                        let data = await editProductSource();
                        Store.dispatch(editProductImportFlow(data.id, data))
                        Store.dispatch(setEditFlowDrawer(false))
                        setSaveLoading(false);

                    }}>
                        <FormControl fullWidth>
                            <Box class="supplier-select">
                                <InputLabel>Product source provider</InputLabel>
                                <Select fullWidth value={productSource ?? ""}
                                        onChange={({target}) => {
                                            setProductSource(
                                                target.value
                                            )
                                        }}>
                                    <MenuItem value="EDC">EDC</MenuItem>
                                    <MenuItem value="Complies">Complies Computer BV</MenuItem>
                                </Select>
                                <TextField required style={{marginTop: "1em"}}
                                           fullWidth
                                           label="Name"
                                           value={sourceData?.name}
                                           onChange={(e) => {
                                               setSourceData({...sourceData, name: e.target.value})
                                           }}/>
                                {productSource === "EDC" ?
                                    <div>
                                        <TextField required style={{marginTop: "1em"}} id="standard-basic"
                                                   fullWidth
                                                   value={sourceData?.apiKey}
                                                   onChange={(e) => {
                                                       setSourceData({...sourceData, apiKey: e.target.value})
                                                   }}
                                                   label="API key"/>
                                        <TextField required
                                                   style={{marginBottom: "1em", marginTop: "1em"}}
                                                   id="standard-basic"
                                                   fullWidth
                                                   value={sourceData?.language}
                                                   onChange={(e) => {
                                                       setSourceData({...sourceData, language: e.target.value})
                                                   }}
                                                   label="Language"/>
                                    </div> : null}
                                {productSource === "Complies" ?
                                    <div>
                                        <TextField required style={{marginTop: "1em"}} id="standard-basic"
                                                   fullWidth
                                                   value={sourceData?.apiKey}
                                                   onChange={(e) => {
                                                       setSourceData({...sourceData, apiKey: e.target.value})
                                                   }}
                                                   label="API key"/>
                                        <FormControl fullWidth>
                                        </FormControl></div> : null}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlinedPrimary"
                                    margin={0}
                                    className={classes.button}
                                >
                                    {saveLoading ?
                                        <CircularProgress size={"1.4em"}/> : 'save'}
                                </Button>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="error"
                                    margin={0}
                                    onClick={async e =>{
                                        e.preventDefault();
                                        Store.dispatch(deleteProductImportFlow(sourceData.productImportFlow.id))
                                        Store.dispatch(setEditFlowDrawer(false))
                                        await deleteFlow();
                                    }}
                                    className={classes.errorButton}
                                    startIcon={<DeleteIcon/>}
                                    disabled={saveLoading}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </FormControl>
                    </form>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default EditProductSourceDrawer;