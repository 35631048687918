import * as React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import ProductSourceCard from "./ProductSourceCard";
import ProductDestinationCard from "./ProductDestinationCard";
import {addWebshop, setAddWebshopDrawer} from "./actions";
import {Store} from "../../store";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    CanvasPathHighlight: {
        stroke: 'rgba(var(--palette-neutral-60,102, 102, 102),1)', strokeWidth: 2, fill: 'none'
    },
    wrapper: {
        position: 'relative'
    }
});

const ProductImportFlow = (props) => {
    const classes = useStyles();
    const flows = useSelector(state => state.productImport.flows);
    const webshops = flows.find(i => i.id === props.flow.id).webshops;
    return (<div className={classes.wrapper}>
        <ProductSourceCard  onClickAdd={() => {
            Store.dispatch(setAddWebshopDrawer(true, props.flow.id, 1));
            Store.dispatch(addWebshop(props.flow.id, {id: 1}));
        }} productSource={props.flow.productSource}/>
        <svg width="632px" height={150 * (webshops?.length === 0 ? 1 : webshops?.length)} focusable="false"
             className="svg-surface">
            {webshops?.map((item, index) => {
                if (index === 0) {
                    return <path className={classes.CanvasPathHighlight} d="M180,40 L316,40"/>
                }
                return <path className={classes.CanvasPathHighlight}
                             d={`M236,40 Q 274 40, 274 72 L274,${(184 * (index)) * 0.93} Q 274 ${(216 * (index)) * 0.93}, 316 ${(216 * (index)) * 0.93}`}/>
            })}
        </svg>
        {webshops?.map((item, index) => <ProductDestinationCard flow={props.flow} webshop={item} index={index}/>)}
    </div>)
}

export default ProductImportFlow;