import * as React from 'react';
import {Children, cloneElement, isValidElement, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useGetIdentity, useTranslate} from 'ra-core';
import {Avatar, Button, Menu, MenuItem, Typography,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {useDataProvider} from "react-admin";
import SubscriptionDialog from "../subscriptions/SubscriptionDialog";

const defaultIcon = <AccountCircle/>;

const useStyles = makeStyles(
    theme => ({
        user: {},
        userButton: {
            textTransform: 'none',
        },
        userButton__Text: {
            marginLeft: '0.2em',
            justifyContent: 'start'
        },
        userButton__PlanInformation: {
            marginTop: '-0.4em',
        },
        userButton__Plan: {
            backgroundColor: '#759AAB',
            color: 'white',
            fontSize: '0.9em',
            padding: '0.2em 0.4em',
            borderRadius: '0.4em',
        },
        flexColumn: {display: 'flex', flexDirection: 'column'},
        flexRow: {display: 'flex', flexDirection: 'row', alignItems: "center"},
        borderBottom: {
            paddingBottom: "0.2em",
            borderBottom: "solid",
            borderBottomWidth: "1px",
            borderBottomColor: "rgba(0, 0, 0, 0.54)"
        },
        headerLeft__Title: {
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "0.8em"
        },
        userPlan: {
            minHeight: "5em"
        },
        headerRight: {
            marginLeft: "1.5em"
        },
        headerRight__Pricing: {
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "1.2em",
            marginBottom: "-0.4em"
        },
        headerRight__BillingCycle: {
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "0.8em"
        },
        headerLeft__PlanName: {
            color: "#759AAB",
            fontSize: "1.2em"
        },
        headerLeft__DaysLeft: {
            alignSelf: "center",
            marginLeft: "0.4em",
            color: "#759AAB",
            fontSize: "0.8em"
        },
        userButton__DaysLeft: {
            color: "#759AAB",
            fontSize: "0.8em",
            marginLeft: '0.4em'
        },
        avatar: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },

    }),
    {name: 'RaUserMenu'}
);

const AnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const TransformOrigin = {
    vertical: 'top',
    horizontal: 'right',
};


const UserButtonSubtext = props => {
    return (
        <div className={props.classes.userButton__PlanInformation}>
            <span className={props.classes.userButton__Plan}>{props.user?.subscription?.subscriptionType}</span>
            {props.user?.subscription?.usingTrial && props.user?.subscription?.isTrialExpired === false ?
                <Typography className={props.classes.userButton__DaysLeft}
                            variant={"span"}>({props.daysInTrail} day-trial left)</Typography> : null
            }
            {props.user?.subscription?.usingTrial && props.user?.subscription?.isTrialExpired ?
                <Typography className={props.classes.userButton__DaysLeft}
                            variant={"span"}>(Trial expired!)</Typography> : null
            }
        </div>
    );
};

const UserMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const translate = useTranslate();
    const {loaded, identity} = useGetIdentity();
    const [user, setUser] = useState(null);
    const [daysInTrialRemaining, setDaysInTrialRemaining] = useState(null);
    const dataProvider = useDataProvider();
    const classes = useStyles(props);
    const [openSubDialog, setOpenSubDialog] = useState(false);
    const [pricingValue, setPricingValue] = useState("0")
    useEffect(() => {
        dataProvider?.getOne('user', {id: null})
            .then(({data}) => {
                setUser(data);
            })
    }, [dataProvider])
    useEffect(() => {
        if (user != null) {
            setDaysInTrialRemaining(parseInt(user.subscription.trialExpiresIn?.split(".")[0]) + 1)
            switch (user.subscription.subscriptionType){
                case "Starter":
                    setPricingValue("0");
                    break;
                case "Basic":
                    setPricingValue("49");
                    break;
                case "Pro":
                    setPricingValue("99");
                    break;
            }
        }
    }, [user])


    const {
        children,
        label = 'ra.auth.user_menu',
        icon = defaultIcon,
        logout,
    } = props;

    if (!logout && !children) return null;
    const open = Boolean(anchorEl);
    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className={classes.user}>
            {loaded && identity?.fullName && identity.fullName !== "null" ? (
                <Button
                    aria-label={label && translate(label, {_: label})}
                    className={classes.userButton}
                    color="inherit"
                    startIcon={
                        identity.avatar ? (
                            <Avatar
                                className={classes.avatar}
                                src={identity.avatar}
                                alt={identity.fullName}
                            />
                        ) : (
                            icon
                        )
                    }
                    onClick={handleMenu}
                >
                    <div className={`${classes.flexColumn} ${classes.userButton__Text}`}>
                        {identity.fullName}
                        <UserButtonSubtext user={user} daysInTrail={daysInTrialRemaining} classes={classes}/>
                    </div>

                </Button>
            ) : (
                <Button
                    aria-label={label && translate(label, {_: label})}
                    className={classes.userButton}
                    color="inherit"
                    startIcon={
                        icon
                    }
                    onClick={handleMenu}
                >
                    <div className={`${classes.flexColumn} ${classes.userButton__Text}`}>
                        My Account
                        <UserButtonSubtext user={user} daysInTrail={daysInTrialRemaining} classes={classes}/>
                    </div>
                </Button>
            )}
            <Menu
                id="menu-appbar"
                disableScrollLock
                anchorEl={anchorEl}
                anchorOrigin={AnchorOrigin}
                transformOrigin={TransformOrigin}
                getContentAnchorEl={null}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    setOpenSubDialog(true)
                }}>
                    <div className={`${classes.flexRow} ${classes.userPlan} ${classes.borderBottom}`}>
                        <div className={classes.flexColumn}>
                            <Typography className={classes.headerLeft__Title} variant={"span"}>Current pricing
                                plan</Typography>
                            <div className={classes.flexRow}>
                                <Typography className={classes.headerLeft__PlanName}
                                            variant={"span"}> {user?.subscription?.subscriptionType}</Typography>

                                {user?.subscription?.usingTrial && user?.subscription?.isTrialExpired === false ?
                                    <Typography className={classes.headerLeft__DaysLeft}
                                                variant={"span"}>({daysInTrialRemaining} day-trial
                                        left)</Typography> : null}
                                {user?.subscription?.usingTrial && user?.subscription?.isTrialExpired ?
                                    <Typography className={classes.headerLeft__DaysLeft}
                                                variant={"span"}>(Trial expired!)</Typography> : null}
                            </div>
                        </div>
                        <div className={`${classes.flexColumn} ${classes.headerRight}`}>
                            <Typography className={classes.headerRight__Pricing} variant={"span"}>${pricingValue}</Typography>
                            <Typography className={classes.headerRight__BillingCycle}
                                        variant={"span"}>month</Typography>
                        </div>
                    </div>
                </MenuItem>
                {Children.map(children, menuItem =>
                    isValidElement(menuItem)
                        ? cloneElement(menuItem, {
                            onClick: handleClose,
                        })
                        : null
                )}
                {logout}
            </Menu>
            <SubscriptionDialog open={openSubDialog} handleClose={() => setOpenSubDialog(false)}/>
        </div>
    );
}

UserMenu.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    label: PropTypes.string,
    logout: PropTypes.element,
    icon: PropTypes.node,
};

export default UserMenu;