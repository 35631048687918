import {NumberField} from 'react-admin';
import React from "react";

const CustomNumberField = (props) => {
    return (
        <NumberField
            source="total.value"
            {...props}
            options={{
                style: 'currency',
                currency: props.record?.total?.currency,
            }}
        />
    )
};
export default CustomNumberField;