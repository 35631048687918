import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import { Box, useMediaQuery } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink, } from 'react-admin';
import products from '../products';
import mappings from '../mappings';
import customers from '../customers';
import orders from '../orders';
import SubMenu from "./SubMenu";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShopifyIcon from '../Icons/ShopifyIcon';

const Menu = ({ onMenuClick, logout, dense = false }) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
        menuSuppliers: true,
        menuShopify: true,
    });
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };


    return (
        <Box mt={1}>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <MenuItemLink
                to={`/order`}
                primaryText={"Orders"}
                leftIcon={<orders.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />

            <SubMenu
                handleToggle={() => handleToggle('menuSuppliers')}
                isOpen={state.menuSuppliers}
                sidebarIsOpen={open}
                name="Suppliers"
                icon={<LocalShippingIcon />}
                dense={dense}
            >

                <MenuItemLink
                    to={`/edc/product`}
                    primaryText={"Products"}
                    leftIcon={<products.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuShopify')}
                isOpen={state.menuShopify}
                sidebarIsOpen={open}
                name="Shopify"
                icon={<ShopifyIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/shopify/mapping`}
                    primaryText={"Mapping"}
                    leftIcon={<mappings.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    );
};

export default Menu;
