import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {Dialog, DialogContent} from "@material-ui/core";
import {useDataProvider, useNotify} from "react-admin";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SubscriptionCard from "./SubscriptionCard";

const useStyles = makeStyles({
    button: {
        fontSize: "1.2rem"
    },
    formControl: {
        marginTop: "2em",
        width: "100%",
    },
    dialogCloseButton: {
        position: "absolute",
        right: "1.2em",
        top: "1em",
    },
    subscriptionCards:{
        display: 'flex',
        justifyContent: 'center',
        flexDirection:'row',
        marginTop: '3em',
        flexWrap: 'wrap'

    },
    header: {
        fontWeight: 700,
        textAlign: 'center'
    },
    subHeader: {
        fontWeight: 300,
        textAlign: 'center',
        marginTop: '-0.5em'
    },
    selectEmpty: {},
});

export default function SubscriptionDialog(props) {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(props.open ?? false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        dataProvider?.getOne('user', {id: null})
            .then(({data}) => {
                setUser(data);
            })
    }, [dataProvider])

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    return (
        <div>
            <Dialog
                open={open}
                onClose={props.handleClose}
                fullScreen
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={props.handleClose}
                        className={classes.dialogCloseButton}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Container maxWidth={'xl'}>
                        <Typography className={classes.header} variant="h3" component="h1" gutterBottom>
                            Choose your plan
                        </Typography>
                        <Typography className={classes.subHeader} variant="h5" component="h2" gutterBottom>
                            All features are enabled with every plan. Choose the plan that fits your needs.
                        </Typography>
                        <div className={classes.subscriptionCards}>
                            <SubscriptionCard active={user?.subscription.subscriptionType == 'Starter'} hasValidSubscription={user?.subscription.hasValidSubscription} usesTrial={user?.subscription.usingTrial} subscriptionType={"Starter"}/>
                            <SubscriptionCard active={user?.subscription.subscriptionType == 'Basic'} hasValidSubscription={user?.subscription.hasValidSubscription} subscriptionType={"Basic"} />
                            <SubscriptionCard active={user?.subscription.subscriptionType == 'Pro'} hasValidSubscription={user?.subscription.hasValidSubscription} subscriptionType={"Pro"} />
                        </div>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    );
}