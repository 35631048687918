import {ChipField} from "react-admin";
import * as React from "react";

const OrderStatusChipField = props => {
    let style = {
        color: "#777"
    }
    switch (props.record?.orderStatus) {
        case "Completed":
            style = {
                background: "#c8d7e1",
                color: "#2e4453"
            }
            break;
        case "Pending":
            style = {
                background: "#f8dda7",
                color: "#94660c"
            }
            break;
        case "Processing":
            style = {
                background: "#c6e1c6",
                color: "#5b841b"
            }
            break;
        case "OnHold":
            style = {
                background: "#f8dda7",
                color: "#94660c"
            }
            break;
        case "Trash":
            style = {
                background: "#eba3a3",
                color: "#761919"
            }
            break;
        default :
            break;
    }
    return (
        <ChipField source="orderStatus" label="Status" style={style} {...props}/>
    );
}


export default OrderStatusChipField;
