import { useState } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'

import { Card, Select, MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import IconFormatBold from '@material-ui/icons/FormatBold';
import IconFormatItalic from '@material-ui/icons/FormatItalic';
import IconFormatListBulleted from '@material-ui/icons/FormatListBulleted';
import IconFormatListNumbered from '@material-ui/icons/FormatListNumbered';
import IconFormatStrikethrough from '@material-ui/icons/FormatStrikethrough';
import IconTitle from '@material-ui/icons/Title';
import IconViewHeadline from '@material-ui/icons/ViewHeadline';
import IconFormatQuote from '@material-ui/icons/FormatQuote';
import IconRedo from '@material-ui/icons/Redo';
import IconUndo from '@material-ui/icons/Undo';
import IconHeight from '@material-ui/icons/Height';
import IconFunctions from '@material-ui/icons/Functions';

import suggestion from './Tiptap/js/suggestion'
import { useEffect } from 'react';

const casedSnakeCase = (str) => str.replaceAll(" ", "_")
const antiCasedSnakeCase = (str) => str.replaceAll("_", " ")

const propsSnakeCasing = (str) => str.replaceAll(/(?<={{(?:\#if )?)([^\{\}\#\/]+)(?=}})/gm, casedSnakeCase)
const depropsSnakeCasing = (str) => str.replaceAll(/(?<={{(?:\#if )?)([^\{\}\#\/]+)(?=}})/gm, antiCasedSnakeCase)

const useStyles = makeStyles({
    wrapper: {
        padding: '0 1rem',
        paddingTop: 'calc(1em + 12px)',
        justifySelf: 'stretch', // not ideal I know
        position: "relative"
    },
    menuBar: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      padding: "0 .25rem",
      whiteSpace: "nowrap",
      borderBottom: "1px solid #e0e0e3",
      overflowX: "scroll"
    },
    menubarButton: {
      backgroundColor: "transparent",
      opacity: "0.8",
      border: "none",
      outline: "none",
      "&:hover": {
        opacity: 1
      }
    },
    menubarButtonIsActive: {
      backgroundColor: "lightgray"
    }
});


const MenuBar = ({ editor, items }) => {

  const classes = useStyles()


  const [ifStatementVariable, setIfStatementVariable] = useState("")

  useEffect(() => items.length && setIfStatementVariable(items[0]), [items])

  if (!editor) {
    return null
  }

  function insetIfStatementInEditor () {
    if (!ifStatementVariable) return alert("Please select a property.")
    // insert if statement start && end
    editor
      .chain()
      .focus()
      .insertContent({
        type: 'mention',
        attrs: {
          id: `#if ${ifStatementVariable}`
        }
      })
      .insertContent({
        type: 'mention',
        attrs: {
          id: "/if"
        }
      })
      .run()
  }

  return (
    <div className={classes.menuBar}>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`${classes.menubarButton} ${editor.isActive('bold') ? classes.menubarButtonIsActive : ''}`}
      >
        <IconFormatBold/>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`${classes.menubarButton} ${editor.isActive('italic') ? classes.menubarButtonIsActive : ''}`}
      >
        <IconFormatItalic/>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={`${classes.menubarButton} ${editor.isActive('strike') ? classes.menubarButtonIsActive : ''}`}
      >
        <IconFormatStrikethrough/>
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={`${classes.menubarButton} ${editor.isActive('paragraph') ? classes.menubarButtonIsActive : ''}`}
      >
        <IconViewHeadline/>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`${classes.menubarButton} ${editor.isActive('heading', { level: 2 }) ? classes.menubarButtonIsActive : ''}`}
      >
        <IconTitle/>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`${classes.menubarButton} ${editor.isActive('bulletList') ? classes.menubarButtonIsActive : ''}`}
      >
        <IconFormatListBulleted/>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`${classes.menubarButton} ${editor.isActive('orderedList') ? classes.menubarButtonIsActive : ''}`}
      >
        <IconFormatListNumbered/>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={`${classes.menubarButton} ${editor.isActive('blockquote') ? classes.menubarButtonIsActive : ''}`}
      >
        <IconFormatQuote/>
      </button>
      <button className={classes.menubarButton} onClick={() => editor.chain().focus().setHardBreak().run()}>
        <IconHeight />
      </button>
      <button className={classes.menubarButton} onClick={() => editor.chain().focus().undo().run()}>
        <IconUndo />
      </button>
      <button className={classes.menubarButton} onClick={() => editor.chain().focus().redo().run()}>
        <IconRedo />
      </button>
      <Select
          style={{ verticalAlign: "bottom"}}
          value={ifStatementVariable}
          onChange={(ev) => setIfStatementVariable(ev.target.value)}
        >
          {
            items.map((val) => {
              return <MenuItem value={val} key={val}>{val}</MenuItem>
            })
          }
        </Select>
      <button
          className={classes.menubarButton}
          onClick={() => insetIfStatementInEditor()}
          title="Insert an if statement to conditionally display text">
        <IconFunctions />
      </button>
    </div>
  )
}

function Editor (props) {
    const classes = useStyles()

    return (
        <Card className={classes.wrapper}>
            { props.children }
        </Card>
    );
}


const Tiptap = (props) => {

    const editor = useEditor({
      extensions: [
        StarterKit,

        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          renderLabel({ options, node }) {
            return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}${options.suggestion.charEnd}`
          },
          suggestion: {
            ...suggestion,
            items: ({ query,  editor }) => {
              if (!props.items) return []
              return props.items.filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
            },
          },
       }),
      ],
      content: '',
      onUpdate: ({ editor }) => {
        // debounce?
        props.onHTML(propsSnakeCasing(editor.getHTML()))
      }
    }, [props.items])

    useEffect(() => {
      if (!editor) return 
      editor.commands.setContent(depropsSnakeCasing(props.initVal))
    }, [editor, props.initVal])

    return (
      <Editor>
        <MenuBar editor={editor} items={props.items}/>
        <EditorContent editor={editor} />
      </Editor>
    )
}

export default Tiptap