export const SET_ADD_ORDER_PLACEMENT_FLOW_DRAWER = 'SET_ADD_ORDER_PLACEMENT_FLOW_DRAWER';
export const SET_EDIT_ORDER_PLACEMENT_FLOW_DRAWER = 'SET_EDIT_ORDER_PLACEMENT_FLOW_DRAWER';
export const ADD_ORDER_PLACEMENT_FLOW = 'ADD_ORDER_PLACEMENT_FLOW';
export const SET_ORDER_PLACEMENT_FLOWS = 'SET_ORDER_PLACEMENT_FLOWS';
export const EDIT_ORDER_PLACEMENT_FLOW = 'EDIT_ORDER_PLACEMENT_FLOW';
export const DELETE_ORDER_PLACEMENT_FLOW = 'DELETE_ORDER_PLACEMENT_FLOW';
export const SET_ADD_ORDER_DESTINATION_DRAWER = 'SET_ADD_ORDER_DESTINATION_DRAWER';
export const SET_EDIT_ORDER_DESTINATION_DRAWER = 'SET_EDIT_ORDER_DESTINATION_DRAWER';
export const DELETE_ORDER_DESTINATION = 'DELETE_ORDER_DESTINATION';
export const ADD_ORDER_DESTINATION = 'ADD_ORDER_DESTINATION';
export const EDIT_ORDER_DESTINATION = 'EDIT_ORDER_DESTINATION';

export const setAddOrderPlacementFlowDrawer = (drawerOpen, id) => ({
    type: SET_ADD_ORDER_PLACEMENT_FLOW_DRAWER,
    payload: {drawerOpen, id},
});

export const setEditOrderPlacementFlowDrawer = (drawerOpen, orderPlacementFlow) => ({
    type: SET_EDIT_ORDER_PLACEMENT_FLOW_DRAWER,
    payload: {drawerOpen, orderPlacementFlow},
});

export const addOrderPlacementFlow = (orderPlacementFlow) => ({
    type: ADD_ORDER_PLACEMENT_FLOW,
    payload: orderPlacementFlow,
});

export const setOrderPlacementFlows = (orderPlacementFlow) => ({
    type: SET_ORDER_PLACEMENT_FLOWS,
    payload: orderPlacementFlow,
});

export const editOrderPlacementFlow = (orderPlacementFlowId, flow) => ({
    type: EDIT_ORDER_PLACEMENT_FLOW,
    payload: {orderPlacementFlowId, flow},
});

export const deleteOrderPlacementFLow = (orderPlacementFlowId) => ({
    type: DELETE_ORDER_PLACEMENT_FLOW,
    payload: orderPlacementFlowId,
});

export const setAddOrderDestinationDrawer = (drawerOpen, orderPlacementFlowId, orderDestinationId) => ({
    type: SET_ADD_ORDER_DESTINATION_DRAWER,
    payload: {drawerOpen, orderPlacementFlowId, orderDestinationId},
});

export const setEditOrderDestinationDrawer = (drawerOpen, orderPlacementFlowId, orderDestinationId) => ({
    type: SET_EDIT_ORDER_DESTINATION_DRAWER,
    payload: {drawerOpen, orderPlacementFlowId, orderDestinationId},
});

export const deleteOrderDestination = (orderPlacementFlowId, orderDestinationId) => ({
    type: DELETE_ORDER_DESTINATION,
    payload: {orderPlacementFlowId, orderDestinationId},
});

export const addOrderDestination = (orderPlacementFlowId, orderDestination) => ({
    type: ADD_ORDER_DESTINATION,
    payload: {orderPlacementFlowId, orderDestination},
});

export const editOrderDestination = (orderPlacementFlowId, orderDestinationId, orderDestination) => ({
    type: EDIT_ORDER_DESTINATION,
    payload: {orderPlacementFlowId, orderDestinationId, orderDestination},
});