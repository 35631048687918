import {
    ADD_PRODUCT_IMPORT_FLOW,
    ADD_WEBSHOP,
    DELETE_PRODUCT_IMPORT_FLOW,
    DELETE_WEBSHOP,
    EDIT_PRODUCT_IMPORT_FLOW,
    EDIT_WEBSHOP,
    SET_ADD_FLOW_DRAWER,
    SET_ADD_WEBSHOP_DRAWER,
    SET_EDIT_FLOW_DRAWER,
    SET_PRODUCT_IMPORT_FLOWS
} from './components/ProductImport/actions';

const nullFlows = {
    addFlowDrawerOpen: {
        drawerOpen: false,
        id: null
    },
    addWebshopDrawerOpen: {
        drawerOpen: false,
        productImportFlowId: null,
        webshopId : null
    },
    editFlowDrawerOpen: {
        drawerOpen: false,
    },
    flows: []
}

const productImportFlowReducer = (
    previousState = nullFlows,
    action
) => {
    if (action.type === ADD_PRODUCT_IMPORT_FLOW) {
        return {
            ...previousState,
            flows: [
                ...previousState.flows,
                action.payload
            ],
        }
    }
    if (action.type === SET_ADD_FLOW_DRAWER) {
        return {
            ...previousState,
            addFlowDrawerOpen: action.payload
        }
    }
    if (action.type === SET_ADD_WEBSHOP_DRAWER) {
        return {
            ...previousState,
            addWebshopDrawerOpen: action.payload
        }
    }
    if (action.type === SET_EDIT_FLOW_DRAWER) {
        return {
            ...previousState,
            editFlowDrawerOpen: action.payload
        }
    }
    if (action.type === DELETE_PRODUCT_IMPORT_FLOW) {
        return {
            ...previousState,
            flows: previousState.flows.filter(i => i.id !== action.payload),
        }
    }
    if (action.type === SET_PRODUCT_IMPORT_FLOWS) {
        return {
            ...previousState,
            flows: action.payload,
        }
    }
    if (action.type === ADD_WEBSHOP) {
        return {
            ...previousState,
            flows: previousState.flows.map(flow => {
                if (flow.id === action.payload.productImportFlowId) {
                    flow.webshops = [...flow.webshops ?? [], action.payload.webshop]
                }
                return flow;
            }),
        }
    }
    if (action.type === EDIT_WEBSHOP) {
        return {
            ...previousState,
            flows: previousState.flows.map(flow => {
                if (flow.id === action.payload.productImportFlowId) {
                    flow.webshops = flow.webshops.map(shop => {
                        if(shop.id === action.payload.webshopId){
                            return action.payload.webshop
                        }
                        return shop
                    })
                }
                return flow;
            }),
        }
    }
    if (action.type === DELETE_WEBSHOP) {
        return {
            ...previousState,
            flows: previousState.flows.map(flow => {
                if (flow.id === action.payload.productImportFlowId) {
                    flow.webshops = flow.webshops.filter(ws => ws.id !== action.payload.webshopId)
                }
                return flow;
            }),
        }
    }
    if (action.type === EDIT_PRODUCT_IMPORT_FLOW) {
        return {
            ...previousState,
            flows: previousState.flows.map(flow => {
                if (flow.id === action.payload.id) {
                    return action.payload.flow;
                }
                return flow;
            }),
        }
    }
    return previousState;
};

export default productImportFlowReducer;
