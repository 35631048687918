import {
    Box,
    Button,
    Drawer,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import * as React from "react";
import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {useNotify} from "react-admin";
import {Store} from "../../store";
import {deleteProductImportFlow, editProductImportFlow, setAddProductImportFlowDrawer} from "./actions";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    container: {
        padding: '1rem 2rem',
        width: '40rem'
    },
    button: {
        marginTop: '2em'
    },
    title: {
        marginTop: '1em',
        marginBotton: '1em'
    },
    spacerLine: {
        paddingTop: '2em',
        borderBottom: '1px solid'
    }
});

const edcSourceFormat = {
    name: null,
    apiKey: null,
    language: null,

}

const AddProductSourceDrawer = (props) => {
    const classes = useStyles()
    const client = useSelector(state => state.user.client);
    const notify = useNotify();

    const [saveLoading, setSaveLoading] = useState(false);
    const [productSource, setProductSource,] = useState("");
    const [edcData, setEdcData,] = useState(edcSourceFormat);
    const flows = useSelector(state => state.productImport);

    const addFlow = async () => {
        const {data} = await (() => {
            try {
                return client.post(`/ProductImportFlow/${productSource}`, edcData)
            } catch (e) {
                notify("Something went wrong.", "error")
            }
        })()
        return data;
    }

    return (
        <Drawer anchor={'right'} open={flows.addFlowDrawerOpen.drawerOpen} onClose={() => {
            Store.dispatch(setAddProductImportFlowDrawer(false, flows.addFlowDrawerOpen.id))
            Store.dispatch(deleteProductImportFlow(flows.addFlowDrawerOpen.id))
        }}>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12} md={12}>
                    <Typography className={classes.title} variant="h6" component="h2">New flow</Typography>

                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        setSaveLoading(true);
                        const data = await addFlow()
                        setSaveLoading(false);
                        Store.dispatch(editProductImportFlow(flows.addFlowDrawerOpen.id, data))
                        Store.dispatch(setAddProductImportFlowDrawer(false))

                    }}>
                        <FormControl fullWidth>
                            <Box class="supplier-select">
                                <InputLabel>Product source provider</InputLabel>
                                <Select fullWidth value={productSource ?? ""}
                                        onChange={({target}) => {
                                            setProductSource(
                                                target.value
                                            )
                                        }}>
                                    <MenuItem value="EDC">EDC</MenuItem>
                                    <MenuItem value="Complies">Complies Computer BV</MenuItem>
                                </Select>
                                <TextField required style={{marginTop: "1em"}}
                                           fullWidth
                                           label="Name"
                                           onChange={(e) => {
                                               setEdcData({...edcData, name: e.target.value})
                                           }}/>
                                {productSource === "EDC" ?
                                    <div>
                                        <TextField required style={{marginTop: "1em"}} id="standard-basic"
                                                   fullWidth
                                                   onChange={(e) => {
                                                       setEdcData({...edcData, apiKey: e.target.value})
                                                   }}
                                                   label="API key"/>
                                        <TextField required
                                                   style={{marginBottom: "1em", marginTop: "1em"}}
                                                   id="standard-basic"
                                                   fullWidth
                                                   onChange={(e) => {
                                                       setEdcData({...edcData, language: e.target.value})
                                                   }}
                                                   label="Language"/>
                                    </div> : null}
                                {productSource === "Complies" ?
                                    <div>
                                        <TextField required style={{marginTop: "1em"}} id="standard-basic"
                                                   fullWidth
                                                   onChange={(e) => {
                                                       setEdcData({...edcData, apiKey: e.target.value})
                                                   }}
                                                   label="API key"/>
                                        <FormControl fullWidth>
                                        </FormControl></div> : null}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlinedPrimary"
                                    margin={0}
                                    className={classes.button}
                                >
                                    {saveLoading ?
                                        <CircularProgress size={"1.4em"} className={classes.white}/> : 'save'}
                                </Button>
                            </Box>
                        </FormControl>
                    </form>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default AddProductSourceDrawer;