import {makeStyles} from '@material-ui/core/styles';
import * as React from "react";
import WebshopCard from "./WebshopCard";
import OrderDestinationCard from "./OrderDestinationCard";
import {addOrderDestination, setAddOrderDestinationDrawer,} from "./actions";
import {Store} from "../../store";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    CanvasPathHighlight: {
        stroke: 'rgba(var(--palette-neutral-60,102, 102, 102),1)', strokeWidth: 2, fill: 'none'
    },
    wrapper: {
        position: 'relative'
    }
});

const OrderPlacementFlow = (props) => {
    const classes = useStyles();
    const flows = useSelector(state => state.orderPlacement.flows);
    const orderDestinations = flows.find(i => i.id === props.flow.id)?.orderDestinations;
    return (<div className={classes.wrapper}>
        <WebshopCard onClickAdd={() => {
            Store.dispatch(setAddOrderDestinationDrawer(true, props.flow.id, 1));
            Store.dispatch(addOrderDestination(props.flow.id, {id: 1}));
        }} orderPlacementFlow={props.flow}/>
        <svg width="632px" height={150 * (orderDestinations?.length === 0 ? 1 : orderDestinations?.length)} focusable="false"
             className="svg-surface">
            {orderDestinations?.map((item, index) => {
                if (index === 0) {
                    return <path className={classes.CanvasPathHighlight} d="M180,40 L316,40"/>
                }
                return <path className={classes.CanvasPathHighlight}
                             d={`M236,40 Q 274 40, 274 72 L274,${(184 * (index)) * 0.93} Q 274 ${(216 * (index)) * 0.93}, 316 ${(216 * (index)) * 0.93}`}/>
            })}
        </svg>
        {orderDestinations?.map((item, index) => <OrderDestinationCard flow={props.flow} orderDestination={item} index={index}/>)}
    </div>)
}

export default OrderPlacementFlow;