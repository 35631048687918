import {
    Box,
    Button,
    Drawer,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {useDataProvider, useNotify} from "react-admin";
import {Store} from "../../store";
import {deleteOrderDestination, editOrderPlacementFlow, setAddOrderDestinationDrawer} from "./actions";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    container: {
        padding: '1rem 2rem',
        width: '40rem'
    },
    button: {
        marginTop: '2em'
    },
    title: {
        marginTop: '1em',
        marginBotton: '1em'
    },
    spacerLine: {
        paddingTop: '2em',
        borderBottom: '1px solid'
    }
});

const AddOrderDestinationDrawer = (props) => {
    const classes = useStyles()
    const client = useSelector(state => state.user.client);
    const notify = useNotify();

    const [saveLoading, setSaveLoading] = useState(false);
    const flows = useSelector(state => state.orderPlacement);
    const orderPlacementFlowId = useSelector(state => state.orderPlacement.addOrderDestinationDrawerOpen.orderPlacementFlowId);
    const orderDestinationId = useSelector(state => state.orderPlacement.addOrderDestinationDrawerOpen.orderDestinationId);
    const [selectedProductSource, setSelectedProductSource] = useState(null);
    const [postData, setPostData] = useState({});
    const [productSources, setProductSources] = useState([]);
    const dataProvider = useDataProvider();

    const addOrderDestination = async () => {
        try {
            const {data} = await (() => {
                return client.post(`/OrderPlacementFlow/${orderPlacementFlowId}/${selectedProductSource.type}/OrderDestination`, postData)
            })()
            Store.dispatch(editOrderPlacementFlow(orderPlacementFlowId, data))
            Store.dispatch(setAddOrderDestinationDrawer(false, null, null))
        } catch (e) {
            notify("Something went wrong.", "error")
        }
    }

    const fetchProductSources = useCallback(async () => {
        const {data: productSources} = await dataProvider.getList(
            'productSource',
            {
                pagination: {page: 1, perPage: 300},
                sort: {field: 'Name', order: 'DESC'},
            }
        );
        setProductSources(productSources)
    }, [dataProvider]);

    useEffect(() => {
        fetchProductSources();
    }, [fetchProductSources]); // es

    return (
        <Drawer anchor={'right'} open={flows.addOrderDestinationDrawerOpen.drawerOpen} onClose={() => {
            Store.dispatch(setAddOrderDestinationDrawer(false, null, null))
            Store.dispatch(deleteOrderDestination(orderPlacementFlowId, orderDestinationId))
        }}>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12} md={12}>
                    <Typography className={classes.title} variant="h6" component="h2">Add order destination
                        (supplier)</Typography>

                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        setSaveLoading(true);
                        await addOrderDestination()
                        setSaveLoading(false);
                    }}>
                        <FormControl fullWidth>
                            <Box class="supplier-select">
                                <InputLabel>Product source provider</InputLabel>
                                <Select fullWidth value={selectedProductSource ?? ""}
                                        onChange={({target}) => {
                                            setSelectedProductSource(
                                                target.value
                                            )
                                            setPostData({
                                                ...postData,
                                                productSourceId: target.value.id
                                            })
                                        }}>
                                    {
                                        productSources.map(i => <MenuItem value={i}>{i.name} ({i.type})</MenuItem>)
                                    }
                                </Select>
                                <TextField required style={{marginTop: "1em"}}
                                           fullWidth
                                           label="Name"
                                           onChange={(e) => {
                                               setPostData({...postData, name: e.target.value})
                                           }}/>
                                {selectedProductSource?.type === "EDC" ?
                                    <div>
                                        <TextField required style={{marginTop: "1em"}} id="standard-basic"
                                                   fullWidth
                                                   onChange={(e) => {
                                                       setPostData({...postData, emailAddress: e.target.value})
                                                   }}
                                                   label="Email Address"/>
                                    </div> : null}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlinedPrimary"
                                    margin={0}
                                    className={classes.button}
                                >
                                    {saveLoading ?
                                        <CircularProgress size={"1.4em"} className={classes.white}/> : 'save'}
                                </Button>
                            </Box>
                        </FormControl>
                    </form>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default AddOrderDestinationDrawer;