import {
    ADD_ORDER_DESTINATION,
    ADD_ORDER_PLACEMENT_FLOW,
    DELETE_ORDER_DESTINATION,
    DELETE_ORDER_PLACEMENT_FLOW,
    EDIT_ORDER_PLACEMENT_FLOW,
    SET_ADD_ORDER_DESTINATION_DRAWER,
    SET_ADD_ORDER_PLACEMENT_FLOW_DRAWER,
    SET_EDIT_ORDER_PLACEMENT_FLOW_DRAWER,
    SET_ORDER_PLACEMENT_FLOWS
} from "./components/OrderPlacement/actions";

const nullFlows = {
    addFlowDrawerOpen: {
        drawerOpen: false,
        id: null
    },
    addOrderDestinationDrawerOpen: {
        drawerOpen: false,
        orderPlacementFlowId: null,
        orderDestinationId: null
    },
    editOrderDestinationDrawerOpen: {
        drawerOpen: false,
        orderPlacementFlowId: null,
        orderDestinationId: null
    },
    editFlowDrawerOpen: {
        drawerOpen: false,
        orderPlacementFlow: {id : null}
    },
    flows: []
}

const OrderPlacementFlowReducer = (
    previousState = nullFlows,
    action
) => {
    if (action.type === ADD_ORDER_PLACEMENT_FLOW) {
        return {
            ...previousState,
            flows: [
                ...previousState.flows,
                action.payload
            ],
        }
    }
    if (action.type === ADD_ORDER_DESTINATION) {
        return {
            ...previousState,
            flows: previousState.flows.map(flow => {
                if (flow.id === action.payload.orderPlacementFlowId) {
                    flow.orderDestinations = [...flow.orderDestinations ?? [], action.payload.orderDestination]
                }
                return flow;
            }),
        }
    }
    if (action.type === DELETE_ORDER_DESTINATION) {
        return {
            ...previousState,
            flows: previousState.flows.map(flow => {
                if (flow.id === action.payload.orderPlacementFlowId) {
                    flow.orderDestinations = flow.orderDestinations.filter(ws => ws.id !== action.payload.orderDestinationId)
                }
                return flow;
            }),
        }
    }
    if (action.type === SET_ADD_ORDER_PLACEMENT_FLOW_DRAWER) {
        return {
            ...previousState,
            addFlowDrawerOpen: action.payload
        }
    }
    if (action.type === SET_EDIT_ORDER_PLACEMENT_FLOW_DRAWER) {
        return {
            ...previousState,
            editFlowDrawerOpen: action.payload
        }
    }
    if (action.type === SET_ADD_ORDER_DESTINATION_DRAWER) {
        return {
            ...previousState,
            addOrderDestinationDrawerOpen: action.payload
        }
    }
    if (action.type === DELETE_ORDER_PLACEMENT_FLOW) {
        return {
            ...previousState,
            flows: previousState.flows.filter(i => i.id !== action.payload),
        }
    }
    if (action.type === EDIT_ORDER_PLACEMENT_FLOW) {
        return {
            ...previousState,
            flows: previousState.flows.map(flow => {
                if (flow.id === action.payload.orderPlacementFlowId) {
                    return action.payload.flow;
                }
                return flow;
            }),
        }
    }
    if (action.type === SET_ORDER_PLACEMENT_FLOWS) {
        return {
            ...previousState,
            flows: action.payload,
        }
    }
    return previousState;
};

export default OrderPlacementFlowReducer;
