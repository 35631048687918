import {Button, Card, Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import {makeStyles} from '@material-ui/core/styles';
import CardContent from "@material-ui/core/CardContent";
import * as React from "react";
import {useSelector} from "react-redux";
import {useNotify} from "react-admin";
import {Store} from "../../store";
import {deleteOrderDestination} from "./actions";

const useStyles = makeStyles({
        card: {
            display: 'flex',
            width: '180px',
            height: '100px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            '&:hover': {
                background: 'rgb(240, 240, 240)',
            }
        },
        deleteButton: {
            opacity: 0,
            marginTop: '0.2em',
            display: 'flex',
            alignSelf: 'center',
            width: 'fit-content',
            background: 'white',
            border: '1px solid rgb(244, 67, 54)',
            '&:hover': {
                background: 'rgb(240, 240, 240)',
                border: '1px solid rgb(244, 67, 54)'
            },
        },
        wrapper: {
            justifyContent: 'center',
            display: 'flex',
            position: 'absolute',
            flexDirection: 'column',
            '&:hover button': {
                transition: 'opacity 100ms ease-in',
                opacity: '1',
            },
            animationName: 'fadeIn',
            opacity: 1,
            "-webkit-animation-name": 'fadeIn',
            animationDuration: '0.5s',
            '-webkit-animation-duration': '0.5',
            animationTimingFunction: 'ease-in-out',
            '-webkit-animation-timing-function': 'ease-in-out',
            visibility: 'visible !important'
        },
    }
);


const OrderDestinationCard = (props) => {
    const classes = useStyles()
    const client = useSelector(state => state.user.client);
    const notify = useNotify();
    const deleteFromDropwonder = async () => {
        try {
            const {data} = await (() => {
                return client.delete(`/OrderPlacementFlow/${props.flow.id}/orderDestination/${props.orderDestination.id}`)
            })()
            Store.dispatch(deleteOrderDestination(props.flow.id, props.orderDestination.id))
        } catch (e) {
            notify("Something went wrong.", "error")
        }
    }
    return (
        <div className={classes.wrapper} style={{left: 316, top: (props.index * 184)}}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h6" component="h2">{props.orderDestination?.title}</Typography>
                    <Typography variant="paragraph">{props.orderDestination?.name}</Typography>
                </CardContent>
            </Card>
            <Button className={classes.deleteButton} onClick={deleteFromDropwonder} variant={"outlined"}
            ><DeleteIcon color={"error"}/> </Button>
        </div>
    )
}

export default OrderDestinationCard;