import {useEffect, useState} from "react"
import {
    Button,
    ButtonGroup,
    Card,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import Tiptap from "../components/Tiptap";
import TiptapSlim from "../components/TiptapSlim";
import {useSelector} from 'react-redux';
// import { Stack } from '@mui/material';
import {Title, useNotify} from "react-admin";
import CircularProgress from '@material-ui/core/CircularProgress';

import {makeStyles} from '@material-ui/core/styles';

// default template for HTTP PUT
const defaultMappingTemplate = {
    "title": {
        type: "template",
        value: ""
    },
    "bodyHtml": {
        type: "template",
        value: ""
    },
    "vendor": {
        type: "Mapping",
        value: "brand"
    },
    "barcode": {
        type: "Mapping",
        value: "eanCode"
    },
    "sku": {
        type: "Mapping",
        value: "sku"
    },
    "weight": {
        type: "Mapping",
        value: "weight"
    },
    "images": {
        type: "Mapping",
        value: "images"
    },
    "price": {
        type: "Mapping",
        value: "price"
    },
    "tags": {
        type: "Mapping",
        value: "tags"
    },
    "variantStockStatus": {
        type: "Mapping",
        value: "variantStockStatus"
    },
}

// config for inputs
const defaultMappingOptions = {
    "title": {
        type: "template",
        options: ["name"],
        value: "",
        valueType: "text"
    },
    "bodyHtml": {
        type: "template",
        options: ["description"],
        value: "",
        valueType: "text"
    },
    "vendor": {
        type: "Mapping",
        value: "brand",
        options: ["brand"],
        valueType: "text"
    },
    "barcode": {
        type: "Mapping",
        value: "eanCode",
        options: ["eanCode"],
        valueType: "text"
    },
    "sku": {
        type: "Mapping",
        value: "sku",
        options: ["sku"],
        valueType: "text"
    },
    "weight": {
        type: "Mapping",
        value: "weight",
        options: ["weight"],
        valueType: "text"
    },
    "images": {
        type: "Mapping",
        value: "images",
        options: ["images"]
    },
    "price": {
        type: "Mapping",
        value: "price",
        options: ["price"]
    },
    "tags": {
        type: "Mapping",
        value: "tags",
        options: ["tags"]
    },
    "variantStockStatus": {
        type: "Mapping",
        value: "variantStockStatus",
        options: ["variantStockStatus"]
    },
}

const getPropertyOptionDefault = (key) => defaultMappingOptions[key]?.options?.[0] ?? key
const getPropertyOptions = (key) => defaultMappingOptions[key]?.options ?? []


const useStyles = makeStyles({
    tile: {
        padding: '2rem',
        margin: '1rem'
    },
    title: {
        margin: '0.5rem 0'
    },
    stack: {
        display: 'grid',
        gridAutoFlow: 'row',
        gridGap: '2rem',
        // justifyContent: 'start',
        justifyItems: 'start'
    },
    spacer: {
        flex: 1,
    },
    white: {
        color: "#fff"
    }
});

function Tile(props) {
    const classes = useStyles()

    const Title = () => props.title ?
        <Typography className={classes.title} variant="h6" component="h2">{props.title}</Typography> : null

    return (
        <Card className={classes.tile} style={props.style}>
            <Title/>
            <div className={classes.stack}>
                {props.children}
            </div>
        </Card>
    );
}


const Mappings = () => {
    const classes = useStyles();
    const client = useSelector(state => state.user.client);
    const notify = useNotify();

    const [name, setName] = useState(defaultMappingOptions["title"].value);
    const [nameInitial, setNameInitial] = useState(defaultMappingOptions["title"].value);
    const [editorHTMLString, setEditorHTMLString] = useState(defaultMappingOptions["bodyHtml"].value);
    const [editorHTMLStringInitial, setEditorHTMLStringInitial] = useState(defaultMappingOptions["bodyHtml"].value);
    const [items, setItems] = useState([]);
    const [mapping, setMapping] = useState({});
    const [resetKey, setResetKey] = useState(new Date().getTime());
    const [saveLoading, setSaveLoading] = useState(false);


    const setMappingAcitve = (value, state) => {
        setMapping({
            ...mapping,
            [value]: {
                type: "Mapping",
                value: state ? value : null
            }
        })
    }

    const handleEditorHTML = val => setEditorHTMLString(val)

    async function saveChanges() {
        const data = {
            ...mapping,
            "title": {
                type: "Template",
                value: name || null
            },
            "bodyHtml": {
                type: "Template",
                value: editorHTMLString || null
            },
        }

        try {
            await client.put('/User/ShopifyMapping', data)
            notify("Mapping saved", "info")
        } catch (e) {
            notify("Something went wrong.", "error")
        }
    }

    const fetchMapping = async () => {
        const {data} = await (() => {
            try {
                return client.get("/User/ShopifyMapping")
            } catch (e) {
                notify("Something went wrong.", "error")
            }
        })()

        setMapping({
            ...defaultMappingTemplate,
            ...(data || {})
        })

        setNameInitial(data?.title?.value ?? "")
        setName(data?.title?.value ?? "")
        setEditorHTMLStringInitial(data?.bodyHtml?.value ?? "")
        setEditorHTMLString(data?.bodyHtml?.value ?? "")
        setResetKey(new Date().getTime())

    }

    function resetData() {
        // refetch data here
        fetchMapping()
    }

    useEffect(() => {
        async function fetchData() {
            const data = await client.get(`/Property`).then(resp => resp.data.map(el => el.name))

            // get alll options for the mappings
            const propertiesOptions = Object.values(defaultMappingOptions)
                .filter(prop => prop.valueType === "number" || prop.valueType === "text")
                .map(prop => prop.options)
                .reduce((acc, cur) => ([...acc, ...(cur || [])]), [])

            setItems([...data, ...propertiesOptions])
            fetchMapping()
        }

        fetchData();

    }, [setItems, setMapping]);


    return (
        <div>
            <Title title="Mappings"/>
            <Grid container spacing={1}>

                {/* Main panel */}
                <Grid item xs={12} md={8}>

                    {/* Text Content */}
                    <Tile>
                        <FormGroup style={{justifySelf: "stretch", width: "50%"}}>
                            <InputLabel>Title</InputLabel>
                            <TiptapSlim label="Title" variant="outlined" initVal={nameInitial}
                                        onHTML={event => setName(event)} items={items} key={`${resetKey}slim`}/>
                        </FormGroup>
                        <FormGroup style={{justifySelf: "stretch"}}>
                            <InputLabel>Description</InputLabel>
                            <Tiptap initVal={editorHTMLStringInitial} onHTML={handleEditorHTML} items={items}
                                    key={resetKey} label="Description" variant="outlined" fullWidth multiline rows={8}/>
                        </FormGroup>
                    </Tile>

                    {/* Sync values */}
                    <Tile title="Value mapping">
                        <Typography>You can select how and what fields should be synced with Shopify when a product is
                            updated. If "Sync value" is disabled, that specific field will remain untouched by
                            Dropwonder.</Typography>
                        <Grid container spacing={2}>
                            {Object.entries(mapping).filter(([key, value]) => value?.type === "Mapping" && key !== "variantStockStatus").map(([key, value]) =>
                                <Grid item xs={12} md={6} lg={6} key={key}>
                                    <FormGroup>
                                        <InputLabel id={'demo-simple-select-label-' + key}>{key}</InputLabel>
                                        <Select
                                            variant="outlined"
                                            labelId={'demo-simple-select-label-' + key}
                                            id="demo-simple-select"
                                            value={getPropertyOptionDefault([key])}
                                            label={key}
                                            // onChange={handleChange}
                                            disabled
                                        >
                                            {getPropertyOptions(key).map(val => <MenuItem value={val}
                                                                                          key={val}>{val}</MenuItem>)}
                                        </Select>
                                        <FormControlLabel label="Sync value" control={<Checkbox checked={!!value.value}
                                                                                                onChange={(ev) => setMappingAcitve(key, ev.target.checked)}/>}/>
                                    </FormGroup>
                                </Grid>
                            )}
                        </Grid>
                    </Tile>
                </Grid>

                {/* Media content */}
                <Grid item xs={12} md={4}>
                    <div style={{position: "sticky", top: "4em", right: "0" }}>
                        <Tile style={{padding: "0"}}>
                            <ButtonGroup variant="contained" style={{width: "100%"}}
                                        aria-label="outlined primary button group">
                                <Button size="large" style={{width: "50%"}} onClick={resetData}>Reset</Button>
                                <Button size="large" style={{width: "50%"}} onClick={() => {
                                    setSaveLoading(true)
                                    saveChanges().then(i => {
                                        setSaveLoading(false)
                                    })
                                }} color="primary">{saveLoading ?
                                    <CircularProgress size={"1.4em"} className={classes.white}/> : 'save'}</Button>
                            </ButtonGroup>
                        </Tile>
                        <br/>
                        <Tile title="How create a template?">
                            <Typography>The Dropwonder template engine uses <a target="_blank" rel="noopener"
                                                                            href="https://handlebarsjs.com/">handlebars.js</a> to
                                configure how the description of your products are synced. A default mapping is always
                                loaded, which you can edit here if you want to.</Typography>

                            <Typography>If a product does not yet exist in Shopify, it's created with as much data as it can
                                find. When a product is updated, it adheres to the value mapping you define
                                below.</Typography>

                        </Tile>
                    </div>
                </Grid>

                {/* Variants */}
                <Grid item xs={12} md={12}>
                    <Tile title="Variants">
                        <Typography>
                            This enables detailed stock management and syncs all the stocks with the current stock at
                            the supplier.
                        </Typography>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={!!mapping["variantStockStatus"]?.value}
                                                                 onChange={(ev) => setMappingAcitve("variantStockStatus", ev.target.checked)}/>}
                                              label="Sync stock status"/>
                        </FormGroup>
                    </Tile>
                </Grid>
            </Grid>
        </div>
    )
}

export default Mappings