import * as React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import AsperionAuth from './oAuth/Asperion/AsperionAuth';
import ShopifyAuthCallback from './oAuth/Shopify/ShopifyAuthCallback';
import ProductCreatePriceList from "./products/ProductCreatePriceList";
import Mappings from "./mappings/Mappings";
import OrderSupplier from "./orders/OrderSupplier/OrderSupplier";


const Routes = [
    <Route path="/configuration" render={() => <Configuration />} />,
    <Route path="/authcallback/asperion" render={() => <AsperionAuth />} />,
    <Route path="/authcallback/shopify" render={() => <ShopifyAuthCallback />} />,
    <Route path="/majestic/product/createpricelist" render={() => <ProductCreatePriceList />} />,
    <Route path="/edc/product/createpricelist" render={() => <ProductCreatePriceList />} />,
    <Route path="/majestic/mapping" render={() => <Mappings />} />,
    <Route path="/shopify/mapping" render={() => <Mappings />} />,
    <Route path="/order/:id/supplier" render={() => <OrderSupplier />} />,
];

export default Routes;