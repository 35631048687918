import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
   items: {
      padding: '0.2rem',
      position: 'relative',
      borderRadius: '0.5rem',
      background: '#FFF',
      color: 'rgba(0, 0, 0, 0.8)',
      overflow: 'hidden',
      fontSize: '0.9rem',
      boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1)'
   },
   item: {
      display: 'block',
      margin: '0',
      width: '100%',
      textAlign: 'left',
      background: 'transparent',
      borderRadius: '0.4rem',
      border: '1px solid transparent',
      padding: '0.2rem 0.4rem'
   },

   itemIsSelected: {
      borderColor: '#000'
   }
})

export const MentionList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const classes = useStyles()

  const selectItem = index => {
    const item = props.items[index]

    if (item) {
      props.command({ id: item })
    }
  }

  const upHandler = () => {
    setSelectedIndex(((selectedIndex + props.items.length) - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className={classes.items}>
      {props.items.map((item, index) => (
        <button
          className={`${classes.item} ${index === selectedIndex ? classes.itemIsSelected : ''}`}
          key={index}
          onClick={() => selectItem(index)}
        >
          {item}
        </button>
      ))}
    </div>
  )
})