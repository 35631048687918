import * as React from 'react';
import {Layout, Sidebar} from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import {lightTheme} from './themes';
import {makeStyles} from "@material-ui/core/styles";


const CustomSidebar = (props) => {
    const classes = useStyles();
    return <Sidebar {...props} children={props.children} classes={classes}/>
}

const LayoutComp = (props) => {
    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={lightTheme}
        />
    );
};

const useStyles = makeStyles(
    theme => ({
        fixed: {
            position: 'fixed',
            height: 'calc(100vh - 3em)',
            overflowX: 'hidden',
            // hide scrollbar
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            width: '100%'
        },
    }),
    { name: 'RaSidebar' }
);

export default LayoutComp;