import { useState } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'

import { Card } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import suggestion from './Tiptap/js/suggestion'
import { useEffect } from 'react';

import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

const casedSnakeCase = (str) => str.replaceAll(" ", "_")
const antiCasedSnakeCase = (str) => str.replaceAll("_", " ")

const propsSnakeCasing = (str) => str.replaceAll(/(?<={{(?:\#if )?)([^\{\}\#\/]+)(?=}})/gm, casedSnakeCase)
const depropsSnakeCasing = (str) => str.replaceAll(/(?<={{(?:\#if )?)([^\{\}\#\/]+)(?=}})/gm, antiCasedSnakeCase)

export const EnterHandler = Extension.create({
  name: 'enterHandler',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('enterHandler'),
        props: {
          handleKeyDown: (view, event) => {
            if (event.key === 'Enter') {
              // do something
              return true
            }

            return false
          },
        },
      }),
    ]
  },
})


const useStyles = makeStyles({
    wrapper: {
        padding: '0 1rem',
        justifySelf: 'stretch', // not ideal I know
        position: "relative",
        height: "3rem"
    }
});


function Editor (props) {
    const classes = useStyles()

    return (
        <Card className={classes.wrapper}>
            { props.children }
        </Card>
    );
}


const TiptapSlim = (props) => {

    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          bold: false,
          code: false,
          italic: false,
          strike: false,
          blockquote: false,
          bulletList: false,
          codeBlock: false,
          // document: false,
          hardBreak: false,
          heading: false,
          horizontalRule: false,
          listItem: false,
          orderedList: false,
          // paragraph: false,
          // text: false
        }),

        // prevent newlines
        EnterHandler,

        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          renderLabel({ options, node }) {
            return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}${options.suggestion.charEnd}`
          },
          suggestion: {
            ...suggestion,
            items: ({ query,  editor }) => {
              if (!props.items) return []
              return props.items.filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
            },
          },
       }),
      ],
      content: '',
      onUpdate: ({ editor }) => {
        // debounce?
        props.onHTML(propsSnakeCasing(editor.getText()))
      }
    }, [props.items])

    useEffect(() => {
      if (!editor) return 
      const textVal = depropsSnakeCasing(props.initVal)
        .replaceAll(/{{([\w\s]+)}}/gm, (full, match) => `<span data-mention="" class="mention" data-id="${match}">${match}</span>`)
        .replaceAll("\n", " ") // if somehow a newline made it in here filter it out

      editor.commands.setContent(`<p> ${textVal} </p>`)
    }, [editor, props.initVal])

    return (
      <Editor>
        <EditorContent editor={editor} />
      </Editor>
    )
}

export default TiptapSlim