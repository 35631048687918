const firebaseConfig = {
    apiKey: "AIzaSyBNT3Qj3OhcRl_amE1-Jy-mbpFIfobp6lM",
    authDomain: "auth.dropwonder.nl",
    databaseURL: "https://finway-willian.firebaseio.com",
    projectId: "finway-willian",
    storageBucket: "finway-willian.appspot.com",
    messagingSenderId: "881381167898",
    appId: "1:881381167898:web:8dc9cc27af07e3784d12a3"
};

export default firebaseConfig;