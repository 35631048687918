import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const SubscriptionProgress = (props) => {
    const StyledProgress = withStyles({
        root: {
            color: props.textColor ?? '#fffaf6'
        }
    })(CircularProgress);
    return <StyledProgress {...props}>{props.children}</StyledProgress>
};
const SubscriptionButton = (props) => {
    const StyledButton = withStyles({
        root: {
            minHeight: '2.5em',
            textTransform: 'none',
            backgroundColor: '#FAF2A1',
            width: '100%',
            color: props.textColor ?? '#000',
            fontSize: '1.1em',
            '&:hover': {
                backgroundColor: props.backgroundColorHover ?? '#FED102',
            },
        },
    })(Button);
    return <StyledButton {...props}>{props.loading ?
        <SubscriptionProgress size={20}/> : props.children}</StyledButton>;
}

export default SubscriptionButton;