import {Box, Button, Drawer, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {useDataProvider, useNotify} from "react-admin";
import {Store} from "../../store";
import {deleteWebshop, editWebshop, setAddWebshopDrawer} from "./actions";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    container: {
        padding: '1rem 2rem',
        width: '40rem'
    },
    button: {
        marginTop: '2em'
    },
    title: {
        marginTop: '1em',
        marginBotton: '1em'
    },
    spacerLine: {
        paddingTop: '2em',
        borderBottom: '1px solid'
    }
});

const AddWebshopDrawer = (props) => {
    const classes = useStyles()
    const client = useSelector(state => state.user.client);
    const notify = useNotify();

    const [saveLoading, setSaveLoading] = useState(false);
    const [webshopData, setWebshopData,] = useState({
        webshopId: null,
    });
    const [webshops, setWebShops] = useState([]);
    const flows = useSelector(state => state.productImport);
    const importFlowId = useSelector(state => state.productImport.addWebshopDrawerOpen.productImportFlowId);
    const webshopId = useSelector(state => state.productImport.addWebshopDrawerOpen.webshopId);
    const dataProvider = useDataProvider();

    const addWebshop = async () => {
        try {
            const {data} = await (() => {
                return client.post(`/ProductImportFlow/${importFlowId}/webshop`, webshopData)
            })()
            Store.dispatch(editWebshop(importFlowId, webshopId, data))
            Store.dispatch(setAddWebshopDrawer(false))
        } catch (e) {
            notify("Something went wrong.", "error")
        }
    }
    const fetchWebshops = useCallback(async () => {
        const {data: webshops} = await dataProvider.getList(
            'webshop',
            {
                pagination: {page: 1, perPage: 300},
                sort: {field: 'Name', order: 'DESC'},
            }
        );
        setWebShops(webshops)
    }, [dataProvider]);

    useEffect(() => {
        fetchWebshops();
    }, [fetchWebshops]); // es

    return (
        <Drawer anchor={'right'} open={flows.addWebshopDrawerOpen.drawerOpen} onClose={() => {
            Store.dispatch(setAddWebshopDrawer(false, null, null))
            Store.dispatch(deleteWebshop(importFlowId, flows.addWebshopDrawerOpen.webshopId))
        }}>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12} md={12}>
                    <Typography className={classes.title} variant="h6" component="h2">Add webshop</Typography>

                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        setSaveLoading(true);
                        await addWebshop()
                        setSaveLoading(false);
                    }}>
                        <FormControl fullWidth>
                            <Box class="supplier-select">
                                <InputLabel>Product source provider</InputLabel>
                                <Select fullWidth value={webshopData.webshopId ?? ""}
                                        onChange={({target}) => {
                                            setWebshopData({
                                                ...webshopData,
                                                webshopId: target.value
                                            })
                                        }}>
                                    {
                                        webshops.map(i => <MenuItem value={i.id}>{i.name}</MenuItem>)
                                    }
                                </Select>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlinedPrimary"
                                    margin={0}
                                    className={classes.button}
                                >
                                    {saveLoading ?
                                        <CircularProgress size={"1.4em"} className={classes.white}/> : 'save'}
                                </Button>
                            </Box>
                        </FormControl>
                    </form>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default AddWebshopDrawer;